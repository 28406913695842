<template>
  <div class="page-orders">
    <InputSearch code="F1" placeholder="Поиск по номеру" @submit="getData"/>

    <template v-if="!load && orders.data.length">
      <table>
        <tr>
          <th>
            №
          </th>
          <th>
            Статус
          </th>
          <th></th>
        </tr>
        <tr v-for="g in orders.data" :key="g.id">
          <td class="text-center">
            <strong>{{ g.id }}</strong><br>
            <small>{{g.goods_count}} наимен.</small>
          </td>
          <td>{{ g.status }}</td>
          <td>
            <button @click="$router.push('/orders/'+g.id)">⇒</button>
          </td>
        </tr>
      </table>
      <Paginator :current="+orders.current_page" :per_page="+orders.per_page" :total="+orders.total" @submit="getData"/>
    </template>
    <EmptyData v-else-if="!load"/>

  </div>
</template>

<script>
import InputSearch from "@/components/InputSearch";
import {ref} from "vue";
import EmptyData from "@/components/EmptyData";
import Paginator from "@/components/Paginator";

export default {
  components: {Paginator, EmptyData, InputSearch},
  setup(props, {emit}) {
    emit('meta', {title: 'Заказы', back: '/'})

    let load = ref('Загрузка...')
    let orders = ref([])
    let search = ''

    const getData = async (params = {}) => {
      params.page = params.page ? params.page : 1
      params.search !== undefined ? search = params.search : params.search = search

      load.value = 'Загрузка...'
      let {data} = await axios.get('v1/orders', {params})
      orders.value = data
      load.value = ''
    }
    getData()

    return {
      load, orders, getData
    }
  }
}
</script>

<style lang="less">
.page-orders {

}
</style>
